import axios from "axios";


const BASE_URL_CF = process.env.VUE_APP_CF_D2C
const BASE_URL = process.env.VUE_APP_BASE_URL_AUTOMARSH
const BASE_URL_CF_AUTOMARSH = process.env.VUE_APP_CF_AUTOMARSH

export const zoneCosts = async (zipCode) => {
    try {
       const {data} = await axios.get(BASE_URL_CF + '/AIG_D2C_AON_MARSH_GET_ZONE?zipCode=' + zipCode)
       return data
    } catch (error) {
        throw error
    }
}

export const emiliPayments = async (body) => {
    try {
       const { data } = await axios.post(BASE_URL_CF + '/AIG_D2C_PAYMENT_EMILI_PROCESS_PAYMENT_V3', body)
       return data
    } catch (error) {
        console.log("error: " + error);
        throw error
    }
}

export const getPlans = async (planId, secureAmount, zona_hidro, zona_sismica, dataCustomer) => {
    try {

        const body = {
            planId, secureAmount, zona_hidro, zona_sismica, dataCustomer
        }
       const {data} = await axios.post(BASE_URL_CF_AUTOMARSH + '/AIG_D2C_MARSH_GET_PLAN', body)
       
       return data
    } catch (error) {
        throw error
    }
}

export const getQuotePDF = async (quoteId,zipCode,catastrofico) => {
    try {
        return await axios.post(BASE_URL + '/_api/v1/quotes/downloadQuotePdf/',{quoteId,zipCode,catastrofico},{
        responseType: "blob",
      });
    } catch (error) {
        throw error
    }
}

export const convertToDollar = async () => {
    try {
        const response = await axios.get("https://openexchangerates.org/api/latest.json?app_id=a50fae975e3349f9bf6b1c480aeb5512");
        const values = response.data;
        const actualRateMXN = values.rates.MXN;
        return actualRateMXN;
    } catch (error) {
        throw error.message;
    }
}

export const saveProgressStatus = async (step, quoteId = null) => {
    try {
        const response = await axios.post(`${BASE_URL_CF_AUTOMARSH}/AIG_D2C_MARSH_TRACK_USER`, {step, quoteId})
        return response.data.quoteId;
    } catch (error) {
        throw error.message;
    }
}
